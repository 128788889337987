import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

interface ILogoProps {
    fixedMode: boolean;
}

export function Logo(props: ILogoProps) {
  const { fixedMode } = props;

  if (fixedMode) {
    return (
      <StaticImage
        src="../images/banner.jpg"
        placeholder="blurred"
        layout="fixed"
        width={50}
        height={50}
        alt="The legendary Enlightened Grillists logo."
        style={{marginRight: '1rem'}}
      />
    );
  }

  return (
    <StaticImage
      src="../images/banner.jpg"
      placeholder="blurred"
      layout="fullWidth"
      alt="The legendary Enlightened Grillists logo."
    />
  );
}
